// import store from "@/state/store";

export default [
    {
        path: '/',
        redirect: {
            name: 'dashboard'
        }
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/account/login.vue"),
        meta: {
            title: "Login",
            // beforeResolve(routeTo, routeFrom, next) {
            //     if (store.getters["auth/loggedIn"]) {
            //         next({name: "dashboard"});
            //     } else {
            //         next();
            //     }
            // },
        },
    },
    {
        path: "/esqueceu-senha",
        name: "forgot-password",
        component: () => import("@/views/account/forgot-password.vue"),
        meta: {
            title: "Recuperar Senha",
        },
    },
    {
        path: "/logout",
        name: "logout",
        component: () => import("@/views/account/logout.vue"),
        meta: {
            title: "Logout",
        },
    },
    {
        path: "/",
        name: "dashboard",
        meta: {
            title: "Dashboard",
            authRequired: true,
        },
        component: () => import("@/views/dashboard/index.vue"),
    },
    {
        path: "/meu-perfil",
        name: "my-profile",
        meta: {
            title: "Meu Perfil",
            authRequired: true,
        },
        component: () => import("@/views/profile/my-profile.vue"),
    },
    {
        path: "/clientes",
        name: "customers",
        meta: {
            title: "Clientes",
            authRequired: true,
        },
        component: () => import("@/views/register/customers-list.vue"),
    },
    {
        path: "/usuarios",
        name: "users",
        meta: {
            title: "Usuários",
            authRequired: true,
        },
        component: () => import("@/views/register/users.vue"),
    },
    {
        path: "/tanques",
        component: () => import("@/components/base/page-base.vue"),
        children: [
            {
                path: "",
                name: "tanks",
                meta: {
                    title: "Tanques",
                    authRequired: true,
                },
                component: () => import("@/views/register/tanks/tanks.vue"),
            },
            {
                path: ":id",
                name: "tanks_details",
                meta: {
                    title: "Detalhes do Tanque",
                    authRequired: true,
                },
                component: () => import("@/views/register/tanks/details.vue"),
            },
        ]
    },
];
