const env = {
    url: 'https://api.telemetria.innovareti.com.br/',
    api: 'https://api.telemetria.innovareti.com.br/api/v1/',
    applicationName: 'BAUMINAS_TELEMETRIA',
    applicationFooter: 'BAUMINAS_TELEMETRIA',
    chartBarBackgroundColor: '#2a4a80',
    title: 'BAUMINAS',
}

export default env
