import axios from "axios";
import env from "@/env";

const clientHttp = axios.create({
    baseURL: env.api,
    headers: {
        'Accept': 'application/json',
    }
})


export default clientHttp;